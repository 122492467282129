
import {defineComponent, ref} from 'vue'
import TemplateService from "@/core/services/TemplateService";

export default defineComponent({
  name: "ListShortCode",
  setup() {
    const data = ref({});
    const load = ()=>{
      TemplateService.getListShortCode().then(res=>{
        data.value = res;
      })
    }
    load();
    return {
      data,
    }
  }
})

