
import { computed, defineComponent, ref } from "vue";
import Entities from "@/components/base/common/Entities.vue";
import KtDatatable from "@/components/kt-datatable/KTDatatable.vue";
import { LoadFilterObjects, LoadPanel } from "@/core/composite/composite";
import { Actions } from "@/store/enums/StoreEnums";
import store from "@/store";
import QuickAction from "@/components/base/action/QuickAction.vue";
import Avatar from "@/components/base/common/Avatar.vue";

export default defineComponent({
  name: "EmailTemplates",
  components: {Avatar, QuickAction, KtDatatable, Entities},
  emits: ['delete', 'edit'],
  setup(_,{emit}) {
    const filterObject = ref({type: 'EMAIL'})
    const tableHeader = ref([
      {
        name: "Company",
        key: "company",
        sortable: true,
      },
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Title",
        key: "subject",
        sortable: true,
      },
      {
        name: "Created By",
        key: "user",
        sortable: true,
      },
      {
        name: "Shared",
        key: "shared",
        sortable: true,
      },
      {
        name: "",
        key: "actions",
        sortable: false,
      }
    ]);
    const onDelete = (template) => {
      emit('delete', template)
    }
    const onEdit = (template) => {
      emit('edit', template)
    }
    const templatePage = computed(() => store.state.TemplateModule.emailPage);
    const currentUser = computed(()=>store.getters.currentUser);
    return {
      ...LoadFilterObjects(Actions.LOAD_TEMPLATES_EMAILS, filterObject.value, ['company','user']),
      tableHeader,
      currentUser,
      templatePage,
      ...LoadPanel(),
      onEdit,
      onDelete,
    }
  }
})
